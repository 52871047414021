<template>
  <div class="app-wrapper app-manager">
    <navbar />
    <div class="main-container">
      <sidebar />
      <div class="app-manage-main">
        <c-menu></c-menu>
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import navbar from "./component/navbar.vue";
import sidebar from "./component/sidebar.vue";
import CMenu from "@/components/CMenu/index";
export default {
  components: {
    navbar,
    sidebar,
    CMenu,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<template>
  <div
    class="c-menu-wrapper"
    :style="{ right: right + 'px', bottom: bottom + 'px' }"
  >
    <el-tooltip class="item" effect="dark" content="管理中心" placement="left">
      <div
        class="link-item"
        @click="$router.push('/manager')"
        v-if="admin && currentPannel != 'manager'"
      >
        <img src="../../assets/images/icon/icon_guanlizhongxin.png" alt="" />
        <!-- <p>管理中心</p> -->
      </div>
    </el-tooltip>
    <el-divider v-if="admin && currentPannel != 'manager'"></el-divider>
    <el-tooltip class="item" effect="dark" content="工作台" placement="left">
      <div
        class="link-item"
        @click="$router.push('/home')"
        title="工作台"
        v-if="currentPannel != 'home'"
      >
        <img src="../../assets/images/icon/icon_gongzuotai.png" alt="" />
        <!-- <p>工作台</p> -->
      </div>
    </el-tooltip>
    <el-divider v-if="currentPannel != 'home'"></el-divider>
    <el-tooltip class="item" effect="dark" content="个人中心" placement="left">
      <div
        class="link-item"
        @click="$router.push('/designer/home')"
        v-if="currentPannel != 'designer'"
      >
        <img src="../../assets/images/icon/icon_gerenzhongxin.png" alt="" />
        <!-- <p>个人中心</p> -->
      </div>
    </el-tooltip>
    <el-divider
      v-if="currentPannel != 'designer' && currentPannel != 'community'"
    ></el-divider>
    <el-tooltip class="item" effect="dark" content="社区" placement="left">
      <div
        class="link-item"
        @click="$router.push('/community')"
        v-if="currentPannel != 'community'"
      >
        <img src="../../assets/images/icon/icon_shequ.png" alt="" />
        <!-- <p>社区</p> -->
      </div>
    </el-tooltip>
  </div>
</template>

<script>
export default {
  data() {
    return {
      admin: false,
    };
  },
  props: {
    right: {
      type: Number,
      default: 40,
    },
    bottom: {
      type: Number,
      default: 105,
    },
  },
  created() {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.admin = userInfo.admin;
  },
  computed: {
    currentPannel() {
      let path = this.$route.path;
      console.log(path);
      if (path === "/home") {
        return "home";
      } else if (path.startsWith("/community")) {
        return "community";
      } else if (path.startsWith("/designer")) {
        return "designer";
      } else if (path.startsWith("/manager")) {
        return "manager";
      }
    },
  },
  methods: {},
};
</script>

<style lang="less">
.c-menu-wrapper {
  position: fixed;
  z-index: 10;
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid #e6e7ea;
  box-shadow: 0px 0px 3px 0px rgba(95, 95, 95, 0.31);
  border-radius: 2px;
  padding: 5px;
  .el-divider--horizontal {
    margin: 5px 0;
  }
  .link-item {
    width: 28px;
    height: 28px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    img {
      width: 18px;
    }
  }
}
</style>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"navbar"},[_c('img',{staticClass:"user-logo",attrs:{"src":require("../../../assets/images/logo.png"),"alt":""}}),_c('div',{staticClass:"nav-tool"},[(_vm.$route.path.includes('/manager/works'))?_c('div',{staticClass:"nav-tool-wrapper nav-tool-work"},[_c('ul',{staticClass:"nav-tool-menu work-manage-menu"},[_c('li',{class:{
            active: _vm.$route.path.includes('/manager/works/collection'),
          },on:{"click":function($event){return _vm.handleWorkPageChange(1)}}},[_vm._v(" 公开作品集 ")]),_c('li',{class:{ active: _vm.$route.path == '/manager/works/category' },on:{"click":function($event){return _vm.handleWorkPageChange(2)}}},[_vm._v(" 作品领域 ")]),_c('li',{class:{ active: _vm.$route.path == '/manager/works/offShelf' },on:{"click":function($event){return _vm.handleWorkPageChange(3)}}},[_vm._v(" 下架作品 ")])]),_c('div',{staticClass:"search-container"})]):_vm._e(),(_vm.$route.path.includes('/manager/member'))?_c('div',{staticClass:"nav-tool-wrapper nav-tool-member"},[_c('ul',{staticClass:"nav-tool-menu"},[_c('li',{class:{
            active: _vm.$route.path == '/manager/member/designer',
          },on:{"click":function($event){return _vm.handleMemberPageChange(1)}}},[_vm._v(" 设计师 ")]),_c('li',{class:{ active: _vm.$route.path == '/manager/member/blacklist' },on:{"click":function($event){return _vm.handleMemberPageChange(2)}}},[_vm._v(" 黑名单 ")])])]):_vm._e(),(_vm.$route.path.includes('/manager/label'))?_c('div',{staticClass:"nav-tool-wrapper nav-tool-member"},[_c('ul',{staticClass:"nav-tool-menu"},[_c('li',{class:{
            active: _vm.$route.path == '/manager/label/category',
          },on:{"click":function($event){return _vm.handleTagPageChange(1)}}},[_vm._v(" 分类管理 ")]),_c('li',{class:{ active: _vm.$route.path == '/manager/label/list' },on:{"click":function($event){return _vm.handleTagPageChange(2)}}},[_vm._v(" 标签列表 ")])])]):_vm._e()]),_c('div',{staticClass:"nav-user"},[_c('el-dropdown',{attrs:{"trigger":"click"},on:{"command":_vm.handleToggleRole}},[_c('span',{staticClass:"el-dropdown-link"},[_c('img',{staticClass:"user-avatar",attrs:{"src":_vm.avatar,"alt":""}}),_c('span',{staticClass:"user-name"},[_vm._v(_vm._s(_vm.username))]),_c('i',{staticClass:"el-icon-arrow-down"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"command":"exit"}},[_vm._v("退出登录")])],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
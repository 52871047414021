<template>
  <div class="sidetree-container sidetree-manage">
    <div class="sidetree-box">
      <!-- <div class="user-container">
        <el-dropdown trigger="click" @command="handleToggleRole">
          <span class="el-dropdown-link">
            {{ username }}<i class="el-icon-caret-bottom el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="workspace">工作台</el-dropdown-item>
            <el-dropdown-item command="personal">个人中心</el-dropdown-item>
            <el-dropdown-item command="exit">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div> -->
      <div class="menu-container">
        <el-scrollbar wrap-class="scrollbar-wrapper">
          <ul class="m-menu">
            <li
              class="m-menu-item"
              @click="handleMenuClick(2)"
              :class="{ active: defActive == 2 }"
            >
              <!-- <i class="iconfont icon-tuceng color-o1"></i> -->

              <img
                src="../../../assets/images/icon/icon_tuceng.png"
                alt=""
                class="icon_tuceng"
              />
              <span class="item-text">作品集管理</span>
            </li>
            <li
              class="m-menu-item"
              @click="handleMenuClick(1)"
              :class="{ active: defActive == 1 }"
            >
              <!-- <i class="iconfont icon-chengyuan-tianjia-tianchong color-g"></i> -->

              <img
                src="../../../assets/images/icon/icon_chengyuan.png"
                alt=""
                class="icon_chengyuan"
              />
              <span class="item-text">设计师管理</span>
            </li>
            <li
              class="m-menu-item"
              @click="handleMenuClick(3)"
              :class="{ active: defActive == 3 }"
            >
              <!-- <i class="iconfont icon-biaoqian color-b"></i> -->

              <img
                src="../../../assets/images/icon/icon_biaoqian2.png"
                alt=""
                class="icon_biaoqian"
              />
              <span class="item-text">标签管理</span>
            </li>
            <li
              class="m-menu-item"
              @click="handleMenuClick(4)"
              :class="{ active: defActive == 4 }"
            >
              <!-- <i class="iconfont icon-jifen color-o2"></i> -->

              <img
                src="../../../assets/images/icon/icon_jifen2.png"
                alt=""
                class="icon_jifen"
              />
              <span class="item-text">积分管理</span>
            </li>
          </ul>
        </el-scrollbar>
      </div>
    </div>
    <!-- <div class="community-btn" @click="$router.push('/community/home')">
      <img src="../../../assets/images/icon/icon_shequ@2x.png" alt="" />
      <span class="text">社区</span>
    </div> -->
    <!-- 修改密码弹框 -->
    <el-dialog
      title="修改密码"
      :visible.sync="dialogFormVisible"
      @close="handleDialogClose"
      :lock-scroll="false"
    >
      <c-form
        v-model="handleData"
        ref="cForm"
        :formRules="formRules"
        :formItems="formItems"
        @submit="submit"
        @resetFields="resetFields"
      >
      </c-form>
    </el-dialog>
  </div>
</template>

<script>
import CForm from "@/components/CForm/index.vue";
import Configs from "../data/config";
import { EventBus } from "@/EventBus";
export default {
  components: { CForm },
  data() {
    let { formItems, formRules } = Configs(this);
    return {
      SUCCESS_CODE: 0,
      formItems,
      formRules,
      username: "", // 用户名
      dialogFormVisible: false,
      handleData: {
        password: "",
        newPassword: "",
        secondPassword: "",
      },
      userInfo: {
        avatar: "",
      },
      currentMenu: 1,
    };
  },
  computed: {
    defActive() {
      let path = this.$route.path;
      if (path.includes("/manager/member")) {
        return 1;
      } else if (path.includes("/manager/works")) {
        return 2;
      } else if (path.includes("/manager/label")) {
        return 3;
      } else if (path.includes("/manager/integral")) {
        return 4;
      }
    },
  },
  created() {
    let nickname = localStorage.getItem("nickname");
    if (nickname) this.username = localStorage.getItem("nickname");
  },
  mounted() {
    EventBus.$on("setCurrent", (data) => {
      // this.handleMenuClick(this.current);
      this.current = data;
    });
  },
  methods: {
    // 切换角色或退出登录
    handleToggleRole(command) {
      console.log(command);
      if (command == "workspace") {
        this.$router.push("/home");
      } else if (command == "personal") {
        this.$router.push("/designer");
      } else if (command == "exit") {
        // 退出登录，清空localStorage
        localStorage.clear();
        this.$router.replace("/login");
        // 清除vuex中的数据
        this.$store.commit("auth/resetState");
      } else {
        let roleId = command.roleId;
        let roleName = command.roleName == "学生端" ? "" : command.roleName;
        localStorage.setItem("roleId", roleId ? roleId : "");
        localStorage.setItem("roleName", roleName);
        this.$store.commit("auth/resetState");
        window.location.reload();
      }
    },
    handleDialogClose() {
      this.$refs.cForm.$refs.cForm.resetFields();
      this.dialogFormVisible = false;
    },
    resetFields() {
      this.dialogFormVisible = false;
    },
    // 修改密码
    submit() {
      this.$http
        .post("/user/modifyPassword", this.handleData)
        .then((res) => {
          if (res.code == this.SUCCESS_CODE) {
            this.$message.success(res.msg);
            this.dialogFormVisible = false;
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    handleMenuClick(menu) {
      this.currentMenu = menu;
      EventBus.$emit("setCurrentNav", menu);
      switch (menu) {
        case 1:
          this.$router.push("/manager/member");
          break;
        case 2:
          this.$router.push("/manager/works");
          break;
        case 3:
          this.$router.push("/manager/label");
          break;
        case 4:
          this.$router.push("/manager/integral");
          break;
      }
    },
  },
};
</script>

<template>
  <div class="navbar">
    <img src="../../../assets/images/logo.png" alt="" class="user-logo" />

    <div class="nav-tool">
      <!-- 作品集头部功能按钮 -->
      <div
        class="nav-tool-wrapper nav-tool-work"
        v-if="$route.path.includes('/manager/works')"
      >
        <!-- <div class="nav-tool-work" v-if="currentIndex == 2"> -->
        <ul class="nav-tool-menu work-manage-menu">
          <li
            :class="{
              active: $route.path.includes('/manager/works/collection'),
            }"
            @click="handleWorkPageChange(1)"
          >
            公开作品集
          </li>
          <li
            :class="{ active: $route.path == '/manager/works/category' }"
            @click="handleWorkPageChange(2)"
          >
            作品领域
          </li>
          <li
            :class="{ active: $route.path == '/manager/works/offShelf' }"
            @click="handleWorkPageChange(3)"
          >
            下架作品
          </li>
        </ul>
        <div class="search-container">
          <!-- <el-input
            placeholder="搜索作品"
            prefix-icon="el-icon-search"
            v-model="searchVal"
            clearable
            @clear="searchByWords"
            @change="searchByWords"
          >
          </el-input> -->
        </div>
      </div>
      <!-- 设计师管理头部功能按钮 -->
      <div
        class="nav-tool-wrapper nav-tool-member"
        v-if="$route.path.includes('/manager/member')"
      >
        <ul class="nav-tool-menu">
          <li
            :class="{
              active: $route.path == '/manager/member/designer',
            }"
            @click="handleMemberPageChange(1)"
          >
            设计师
          </li>
          <li
            :class="{ active: $route.path == '/manager/member/blacklist' }"
            @click="handleMemberPageChange(2)"
          >
            黑名单
          </li>
        </ul>
      </div>
      <!-- 标签管理功能头部功能按钮 -->
      <div
        class="nav-tool-wrapper nav-tool-member"
        v-if="$route.path.includes('/manager/label')"
      >
        <ul class="nav-tool-menu">
          <li
            :class="{
              active: $route.path == '/manager/label/category',
            }"
            @click="handleTagPageChange(1)"
          >
            分类管理
          </li>
          <li
            :class="{ active: $route.path == '/manager/label/list' }"
            @click="handleTagPageChange(2)"
          >
            标签列表
          </li>
        </ul>
      </div>
    </div>
    <div class="nav-user">
      <el-dropdown trigger="click" @command="handleToggleRole">
        <span class="el-dropdown-link">
          <img :src="avatar" alt="" class="user-avatar" />
          <span class="user-name">{{ username }}</span>
          <i class="el-icon-arrow-down"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <!-- <el-dropdown-item command="workspace">工作台</el-dropdown-item>
          <el-dropdown-item command="personal">个人中心</el-dropdown-item> -->
          <el-dropdown-item command="exit">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/EventBus";
export default {
  data() {
    return {
      currentIndex: 1,
      currentWorkMenu: 1,
      searchVal: "",
      username: "",
      avatar: "",
      admin: false, // 是否管理员
    };
  },
  created() {
    let nickname = localStorage.getItem("nickname");
    if (nickname) this.username = localStorage.getItem("nickname");
    this.admin = JSON.parse(localStorage.getItem("userInfo")).admin;
    this.avatar = JSON.parse(localStorage.getItem("userInfo")).photo;
  },
  mounted() {
    EventBus.$on("setCurrentNav", (data) => {
      this.currentIndex = data;
    });
  },
  methods: {
    handleToggleRole(command) {
      console.log(command);
      if (command == "workspace") {
        this.$router.push("/home");
      } else if (command == "personal") {
        this.$router.push("/designer/home");
      } else if (command == "exit") {
        // 退出登录，清空localStorage
        localStorage.clear();
        this.$router.replace("/login");
        // 清除vuex中的数据
        // this.$store.commit("auth/resetState");
      }
    },
    // 作品管理菜单切换
    handleWorkPageChange(data) {
      this.currentWorkMenu = data;
      switch (data) {
        case 1:
          this.$router.push("/manager/works/collection");
          break;
        case 2:
          this.$router.push("/manager/works/category");
          break;
        case 3:
          this.$router.push("/manager/works/offShelf");
          break;
      }
      // EventBus.$emit("setWorkPage", data);
    },
    // 成员管理菜单切换
    handleMemberPageChange(data) {
      switch (data) {
        case 1:
          this.$router.push("/manager/member/designer");
          break;
        case 2:
          this.$router.push("/manager/member/blacklist");
          break;
      }
    },
    // 标签管理菜单切换
    handleTagPageChange(data) {
      switch (data) {
        case 1:
          this.$router.push("/manager/label/category");
          break;
        case 2:
          this.$router.push("/manager/label/list");
          break;
      }
    },
    // 作品管理中搜索作品
    searchByWords() {},
  },
  beforeDestroy() {
    EventBus.$off("getCurrentPage");
  },
};
</script>

